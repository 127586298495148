import React from "react";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";
import OptionBlockType from "../OptionBlockType";

interface DropdownBlockTypeProps {
  block: OgBlockTypeDto;
}

const DropdownBlockType = (props: DropdownBlockTypeProps): JSX.Element => {
  const { block } = props;

  // TODO: found.text and notFound.text must be localized as soon as the backend (OG TM) supports translation for tasks
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "4px" }}
      id="dropdown-block-type"
      data-cr="dropdown-block-type"
    >
      <div className="task-widget-multi-options-vert">
        <div className="task-widget-multi-options-inner">
          <OptionBlockType opId={block.found.id} opText={block.found.text} />
          <OptionBlockType
            opId={block.notFound.id}
            opText={block.notFound.text}
          />
        </div>
      </div>
    </div>
  );
};

export default DropdownBlockType;
