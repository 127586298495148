// General
import React, { useEffect, useState } from "react";
import { getNewId } from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { Handle, Position } from "reactflow";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Styles
// Store
import { BehaviorSubject } from "rxjs";
import { Button } from "dms-lib";
import AddRounded from "@mui/icons-material/AddRounded";
import { selectOperatorGuide, useStoreDispatch } from "../../../../../../store";
import { operatorGuideActions } from "../../../../../../store/operatorGuide";
import CompassTextField from "../../../../../commons/compassTextField/CompassTextField";
// Models
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";
import { OgTemplateDto } from "../../../../../../models/ogTemplate/OgTemplateDto";
import { OgBlockTypeLinkDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeLinkDto";
// Utils
import { removeStringSuffix } from "../../../../../../utils/Util";
// Helpers
import { SOURCE_HANDLE_RIGHT_SUFFIX } from "../../../helpers/edges";

interface MultiOptionsNodeBlockProps {
  onRemove?: (blockId: string, optionId: string) => void;
  block?: OgBlockTypeDto;
  ogTemplateSubject?: BehaviorSubject<OgTemplateDto>;
  errorCallback?: (msg: string) => void;
}

const MultiOptionsNodeBlock = (
  props: MultiOptionsNodeBlockProps
): JSX.Element => {
  const { onRemove, block, ogTemplateSubject, errorCallback } = props;

  const [editMode, setEditMode] = useState(false);
  const [fieldInEdition, setFieldInEdition] = useState(null);
  const [options, setOptions] = useState(block.options);
  const { templateStub } = useSelector(selectOperatorGuide);
  const dispatch = useStoreDispatch();

  useEffect(() => {
    if (options) {
      block.options = options;
    }
  }, [options]);

  const setModified = (isModified: boolean) => {
    const ogTemplate = ogTemplateSubject.value;
    ogTemplate.modified = isModified;
    ogTemplateSubject.next(ogTemplate);
  };

  const updateCallbackHandler = (txtResponse) => {
    if (txtResponse?.trim().length > 0) {
      const blockOption = block.options.find((op) => op.id === fieldInEdition);
      if (blockOption) {
        dispatch(operatorGuideActions.removeTemplateStub(fieldInEdition));
        blockOption.text = txtResponse;
        setModified(true);
      }
      dispatch(operatorGuideActions.setIsEditingTaskText(false));
    }
    setFieldInEdition(null);
    setEditMode(false);
  };

  const onClickOptionHandler = (opId: string) => {
    if (!editMode) {
      setEditMode(true);
      setFieldInEdition(opId);
    }
  };

  const onClickRemoveOption = (opId: string) => {
    if (options.length > 2) {
      onRemove(block.id, opId);
      setOptions(options.filter((opt) => opt.id !== opId));
    } else {
      errorCallback("Failed to remove option. Have at least 2 options.");
    }
  };

  // Handler IDs should not be equal
  // Therefore in order to keep backwards compatibility we only add the suffix to the right handlers
  const multiOptions = () => {
    const divOptions = [];
    options?.forEach((op) => {
      const realOpId = removeStringSuffix(op.id, SOURCE_HANDLE_RIGHT_SUFFIX);
      divOptions.push(
        <div className="task-node-block-medium">
          {editMode && op.id === fieldInEdition ? (
            <div
              onMouseLeave={() => {
                dispatch(operatorGuideActions.setIsEditingTaskText(false));
              }}
              onMouseEnter={() => {
                dispatch(operatorGuideActions.setIsEditingTaskText(true));
              }}
            >
              <CompassTextField
                updateCallback={(e) => {
                  updateCallbackHandler(e);
                }}
                showSuccess={false}
                showFailure={false}
                editButtonHidden
                textFieldId={op.id}
                fieldIdInEdition={editMode ? fieldInEdition : null}
                maxTextLength={255}
                backButton={false}
                defaultValue={
                  templateStub.indexOf(fieldInEdition) >= 0 ? null : op.text
                }
                safeString={false}
              />
            </div>
          ) : (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="text-span-wrapper"
                onDoubleClick={() => {
                  onClickOptionHandler(op.id);
                  dispatch(operatorGuideActions.setIsEditingTaskText(true));
                }}
              >
                <span className="wrap-text">{op.text}</span>
              </div>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => onClickRemoveOption(op.id)}
                icon
              >
                <CloseRoundedIcon
                  className="task-node-controls-icon"
                  fontSize="small"
                />
              </Button>
            </>
          )}
          <Handle
            id={realOpId}
            type="source"
            position={Position.Left}
            className="node-block-handles"
            isConnectable
            data-cr="node-block-handles-l"
          />
          <Handle
            id={`${realOpId}${SOURCE_HANDLE_RIGHT_SUFFIX}`}
            type="source"
            position={Position.Right}
            className="node-block-handles"
            isConnectable
            data-cr="node-block-handles-r"
          />
        </div>
      );
    });

    return <>{...divOptions}</>;
  };

  const addNewOptionHandler = () => {
    const newOption = {} as OgBlockTypeLinkDto;
    newOption.text = "New Option";
    newOption.id = getNewId();
    dispatch(operatorGuideActions.addTemplateStub(newOption.id));
    setOptions([...options, newOption]);
  };

  return (
    <div className="task-node-multiop" data-cr="task-node-multiop">
      {multiOptions()}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => addNewOptionHandler()}
          icon
        >
          <AddRounded />
        </Button>
      </div>
    </div>
  );
};
MultiOptionsNodeBlock.defaultProps = {
  onRemove: undefined,
  block: null,
  errorCallback: () => null,
  ogTemplateSubject: undefined,
};

export default MultiOptionsNodeBlock;
