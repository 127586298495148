import React from "react";
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";
import { SOURCE_HANDLE_RIGHT_SUFFIX } from "../../../helpers/edges";
import { removeStringSuffix } from "../../../../../../utils/Util";
import OptionNodeBlock from "../OptionNodeBlock";

interface DropdownNodeBlock {
  block: OgBlockTypeDto;
}

const DropdownNodeBlock = (props: DropdownNodeBlock): JSX.Element => {
  const { block } = props;

  // TODO: opText must be localized as soon as the backend (OG TM) supports translation for tasks
  return (
    <div className="task-node-multiop" data-cr="task-node-found-and-notfound">
      <OptionNodeBlock
        opId={removeStringSuffix(block.found.id, SOURCE_HANDLE_RIGHT_SUFFIX)}
        opText={block.found.text}
      />
      <OptionNodeBlock
        opId={removeStringSuffix(block.notFound.id, SOURCE_HANDLE_RIGHT_SUFFIX)}
        opText={block.notFound.text}
      />
    </div>
  );
};

export default DropdownNodeBlock;
