// General
import React from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete, OptionType } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models

import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import { EventTypesDto } from "../../../../../models/subsystems/EventTypesDto";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
import { RuleDto } from "../../../../../models/incidents/RuleDto";

interface BehaviourTriggerEventTypeProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  eventTypes: EventTypesDto;
  loading: boolean;
  setFieldValue: UseFormSetValue<RuleDto>;
  watchField: UseFormWatch<RuleDto>;
}

const BehaviourTriggerEventType = (
  props: BehaviourTriggerEventTypeProps
): JSX.Element => {
  const {
    ruleId,
    readOnlyMode,
    control,
    eventTypes,
    loading,
    errors,
    setFieldValue,
    watchField,
  } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <span>{translate("behaviors.triggerFieldsDescription.eventType")}</span>
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.eventTypeId"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete
                {...field}
                value={{ id: field.value }}
                dataCr="incident-trigger-event-type"
                options={
                  eventTypes?.eventTypes.map((element) => {
                    return { id: element };
                  }) || []
                }
                getOptionLabel={(option) => option?.id || ""}
                id={`trigger-event-type-autocomplete-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.triggerFieldsDescription.eventTypePlaceholder"
                )}
                onChangeCallback={(data, _e) => {
                  const val = (data as OptionType)?.id ?? "";
                  onChange(val);
                  if (
                    watchField("behaviour.description") ===
                    translate("behaviors.placeholders.newBehavior")
                  ) {
                    setFieldValue("behaviour.description", val.toString());
                  }
                }}
                error={!!errors.trigger?.eventTypeId}
                errorMessage={translate(
                  errors.trigger?.eventTypeId?.message ||
                    errors.trigger?.message,
                  { label: translate("behaviors.fields.eventTypeId") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

BehaviourTriggerEventType.defaultProps = {
  errors: undefined,
};

export default BehaviourTriggerEventType;
