import { Connection, Edge } from "reactflow";
import {
  newEdge,
  INITIAL_ID,
  INITIAL_SOURCE,
  SOURCE_HANDLE_RIGHT_SUFFIX,
} from "./edges";
import { OgTaskTypeDto } from "../../../../models/ogTaskTypes/OgTaskTypeDto";

import { removeStringSuffix } from "../../../../utils/Util";

export const matchSourceHandles = (
  existingSourceHandle: string,
  newSourceHandle: string
): boolean => {
  const transformedExistingSourceHandle = removeStringSuffix(
    existingSourceHandle,
    SOURCE_HANDLE_RIGHT_SUFFIX
  );

  const transformedNewSourceHandle = removeStringSuffix(
    newSourceHandle,
    SOURCE_HANDLE_RIGHT_SUFFIX
  );

  return transformedExistingSourceHandle === transformedNewSourceHandle;
};

export const insertConnectionTaskType = (
  task: OgTaskTypeDto,
  params: Edge
): void => {
  task?.typeBlocks?.forEach((block) => {
    if (matchSourceHandles(block.button?.id, params.sourceHandle)) {
      block.button.linkNextTask = params.target;
    } else if (
      matchSourceHandles(block.positiveAnswer?.id, params.sourceHandle)
    ) {
      block.positiveAnswer.linkNextTask = params.target;
    } else if (
      matchSourceHandles(block.negativeAnswer?.id, params.sourceHandle)
    ) {
      block.negativeAnswer.linkNextTask = params.target;
    } else if (matchSourceHandles(block.found?.id, params.sourceHandle)) {
      block.found.id = params.sourceHandle; // this is necessary to know which handle (right and left) was configured
      block.found.linkNextTask = params.target;
    } else if (matchSourceHandles(block.notFound?.id, params.sourceHandle)) {
      block.notFound.id = params.sourceHandle; // this is necessary to know which handle (right and left) was configured
      block.notFound.linkNextTask = params.target;
    } else {
      block.options?.forEach((obj) => {
        if (matchSourceHandles(obj.id, params.sourceHandle)) {
          obj.id = params.sourceHandle;
          obj.linkNextTask = params.target;
        }
      });
    }
  });
};

export const obtainConnections = (task: OgTaskTypeDto): Edge[] => {
  const connections: Edge[] = [];

  task?.typeBlocks?.forEach((block) => {
    if (block.button?.linkNextTask) {
      connections.push(newEdge(task.id, block.button));
    }
    if (block.positiveAnswer?.linkNextTask) {
      connections.push(newEdge(task.id, block.positiveAnswer));
    }
    if (block.negativeAnswer?.linkNextTask) {
      connections.push(newEdge(task.id, block.negativeAnswer));
    }

    // At the moment only multioption and db search nodes have two edge handlers, one at right and other at the left
    block.options?.forEach((obj) => {
      if (obj.linkNextTask) {
        connections.push(newEdge(task.id, obj));
      }
    });

    if (block.found?.linkNextTask) {
      connections.push(newEdge(task.id, block.found));
    }
    if (block.notFound?.linkNextTask) {
      connections.push(newEdge(task.id, block.notFound));
    }
  });

  return connections;
};

export const connectionExists = (
  connection: Connection,
  edges: Edge[]
): boolean => {
  return edges?.some(
    (edge) =>
      (edge.id === INITIAL_ID && connection.source === INITIAL_SOURCE) ||
      (edge.source === connection.source &&
        matchSourceHandles(edge.sourceHandle, connection.sourceHandle))
  );
};
