import React from "react";
import { Handle, Position } from "reactflow";
import { SOURCE_HANDLE_RIGHT_SUFFIX } from "../../helpers/edges";

interface OptionNodeBlockProps {
  opId: string;
  opText: string;
}

const OptionNodeBlock = (props: OptionNodeBlockProps): JSX.Element => {
  const { opId, opText } = props;

  return (
    <div className="task-node-block-medium">
      <div className="text-span-wrapper">
        <span className="wrap-text">{opText}</span>
      </div>
      <Handle
        id={`${opId}`}
        type="source"
        position={Position.Left}
        className="node-block-handles"
        isConnectable
        data-cr="node-block-handles-l"
      />
      <Handle
        id={`${opId}${SOURCE_HANDLE_RIGHT_SUFFIX}`}
        type="source"
        position={Position.Right}
        className="node-block-handles"
        isConnectable
        data-cr="node-block-handles-r"
      />
    </div>
  );
};

export default OptionNodeBlock;
