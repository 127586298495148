import React from "react";

interface OptionBlockTypeProps {
  opId: string;
  opText: string;
}

const OptionBlockType = (props: OptionBlockTypeProps): JSX.Element => {
  const { opId, opText } = props;

  return (
    <div className="compass-rounded-border task-input-text-default" key={opId}>
      {opText}
    </div>
  );
};

export default OptionBlockType;
